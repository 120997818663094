import { Loader } from '@googlemaps/js-api-loader';

import Log from './Log.ts';
import PublicEnvironment from './PublicEnvironment.ts';

declare const globalThis: any;

export type PredictionType = 'address' | 'postal_code' | 'locality';

export const getGeocoder = async () => {
  await loadGoogleMaps();
  const { Geocoder } = (await google.maps.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
  return new Geocoder();
};

export const getAutocompleteService = async () => {
  await loadGoogleMaps();
  const { AutocompleteService } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary;
  return new AutocompleteService();
};

/**
 * Wrapper around google.maps.Geocoder.geocode(). Handles errors and applys componentRestrictions.
 */
export const geocode = async (request: google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult[] | null> => {
  let result: google.maps.GeocoderResult[] | null = null;

  try {
    const geocoder = await getGeocoder();
    await geocoder.geocode(
      {
        componentRestrictions: {
          country: 'AU',
        },
        ...request,
      },
      (res, status) => {
        try {
          if (status === google.maps.GeocoderStatus.OK || status === google.maps.GeocoderStatus.ZERO_RESULTS) {
            result = res;
            // return out of callback fn()
            return;
          }

          Log.error('geocode() request returned bad status', { request, res, status });
          throw Error(`geocode() request returned bad status: ${status}`);
        } catch (error) {
          Log.error('Google Maps: geocode() failed', { error });
        }
      },
    );
  } catch (error) {
    Log.error('Google Maps: geocode() failed', { error });
  }

  return result;
};

/**
 * Wrapper around google.maps.places.getPlacePredictions(). Handles errors and applies the following (overridable) to request:
 * - componentRestrictions
 * - language
 * - types
 */
export const getPlacePredictions = async (
  request: google.maps.places.AutocompletionRequest,
  types: PredictionType[] = ['address'],
): Promise<google.maps.places.AutocompletePrediction[] | null> => {
  let result: google.maps.places.AutocompletePrediction[] | null = null;

  try {
    const autocompleteService = await getAutocompleteService();
    const res = await autocompleteService.getPlacePredictions(
      {
        componentRestrictions: {
          country: 'AU',
        },
        language: 'en-AU',
        // see: https://developers.google.com/maps/documentation/javascript/supported_types
        types,
        ...request,
      },
      (_, status) => {
        if (
          status !== google.maps.places.PlacesServiceStatus.OK &&
          status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          Log.error(`Google Maps: getPlacePredictions() returned bad status: ${status}`, {});
        }
      },
    );
    result = res.predictions;
  } catch (error) {
    console.error('catcherr', error);
    Log.error('Google Maps: getPlacePredicitions() failed', { error });
  }

  return result;
};

/**
 * Imports core google maps library. This sets `google.maps` into global scope.
 * Individual libraries still need to be imports via `await google.maps.importLibrary()`
 *
 *
 * See: https://developers.google.com/maps/documentation/javascript/libraries#typescript for more information
 */
const loadGoogleMaps = async () => {
  // Global callback function gm calls on authentication error
  // https://developers.google.com/maps/documentation/javascript/events#auth-errors
  if (!globalThis.gm_authFailure) {
    globalThis.gm_authFailure = () => {
      Log.error('Google Maps: Auth Failure', {});
    };
  }

  // Check if Google Maps has already been loaded
  if (!window?.google?.maps) {
    await new Loader({
      // purposefully supplying a bad key vs empty to force `gm_authFailure()` to call, otherwise will silently fail
      apiKey: PublicEnvironment.get('GOOGLE_API_KEY'),
      version: 'weekly',
      // Use .importLibrary('core'); to make google.maps available in the global scope.
    }).importLibrary('core');
  }

  return;
};
