import React, { forwardRef, useRef, useState } from 'react';
import cc from 'classcat';
import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  flip,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';

import IconSearch from '@/common/icons/tabler/search.svg';
import IconX from '@/common/icons/tabler/x.svg';

import Button from '../Button/index.tsx';

import styles from './styles.module.scss';

interface ItemProps {
  children: React.ReactNode;
  active: boolean;
}

interface AutoCompleteProps<T extends Record<string, any>> {
  placeholder: string;
  suggestionResultsHeading?: string;
  styleSearchOverride?: string;
  styleInputOverride?: string;
  onSuggestionsRequested: (value: string) => Promise<Array<T>>;
  onSuggestionSelected: (suggestion: T) => void;
  getSuggestionValue: (suggestion: T) => string;
  renderSuggestion: (suggestion: T, inputValue: string) => React.ReactNode;
}

function AutoComplete<T extends Record<string, any>>({
  placeholder,
  suggestionResultsHeading,
  styleSearchOverride = '',
  styleInputOverride = '',
  onSuggestionsRequested,
  onSuggestionSelected,
  getSuggestionValue,
  renderSuggestion,
}: AutoCompleteProps<T>) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [suggestions, setSuggestions] = useState<T[]>([]);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([role, dismiss, listNav]);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length >= 3) {
      setOpen(true);
      setActiveIndex(0);
      const results = await onSuggestionsRequested(value);
      setSuggestions(results);
    } else {
      setOpen(false);
      setSuggestions([]);
    }
  };

  const handleClearButtonClick = () => {
    setInputValue('');
    setOpen(false);
  };

  return (
    <div className={styleSearchOverride || styles.search}>
      <div className={styleInputOverride || styles.inputWrapper}>
        <input
          {...getReferenceProps({
            ref: refs.setReference,
            onChange,
            value: inputValue,
            placeholder: placeholder,
            'aria-autocomplete': 'list',
            onKeyDown(event) {
              if (event.key === 'Enter' && activeIndex != null && suggestions[activeIndex]) {
                const selectedSuggestion = suggestions[activeIndex];
                setActiveIndex(null);
                setOpen(false);
                onSuggestionSelected(selectedSuggestion);
                setInputValue('');
              }
            },
          })}
        />
        {inputValue.length > 0 && (
          <button onClick={handleClearButtonClick} className={styles.clearButton} data-testid="clear-button">
            <IconX height={24} width={24} />
          </button>
        )}
      </div>

      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
            <div
              className={styles.suggestions}
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                },
              })}
            >
              <div data-testid="suggestion-lists">
                {suggestionResultsHeading && (
                  <div className={cc([styles.suggestion, styles.resultsHeading])} data-disabled="true">
                    {suggestionResultsHeading}
                  </div>
                )}
                {suggestions.map((item, index) => (
                  <SuggestionItem
                    key={`${index} - ${getSuggestionValue(item)}`}
                    {...getItemProps({
                      ref(node) {
                        listRef.current[index] = node;
                      },
                      onClick() {
                        setInputValue(getSuggestionValue(item));
                        setOpen(false);
                        onSuggestionSelected(item);
                        setInputValue('');
                        refs.domReference.current?.focus();
                      },
                    })}
                    active={activeIndex === index}
                  >
                    {renderSuggestion(item, inputValue)}
                  </SuggestionItem>
                ))}
              </div>
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
      <Button styleOverride={styles.searchButton} style="solid" disabled={false} aria-label="Search">
        <IconSearch width={24} height={24} />
      </Button>
    </div>
  );
}

const SuggestionItem = forwardRef<HTMLDivElement, ItemProps & React.HTMLProps<HTMLDivElement>>(
  ({ children, active, ...rest }, ref) => {
    return (
      <div className={styles.suggestion} ref={ref} role="option" aria-selected={active} {...rest}>
        {children}
      </div>
    );
  },
);
SuggestionItem.displayName = 'SuggestionItem';

export default AutoComplete;
