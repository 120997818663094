import React from 'react';
import { Handles, Slider, SliderProps, Tracks } from 'react-compound-slider';

import SliderThumbIcon from '@/common/icons/Thumb.svg';

import styles from './styles.module.scss';

type Props = Omit<SliderProps, 'children' | 'mode' | 'flatten'> & {
  ThumbIcon?: () => JSX.Element;
  DisabledThumbIcon?: () => JSX.Element;
};

const FilterRangeSlider = ({
  ThumbIcon = () => <SliderThumbIcon />,
  DisabledThumbIcon = () => <SliderThumbIcon className={styles.disabledIcon} />,
  ...sliderProps
}: Props) => {
  if (sliderProps.disabled) return null;
  return (
    <div className={styles.rangeSlider}>
      <div className={styles.slider}>
        <Slider step={1} mode={2} flatten {...sliderProps}>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className={styles.handles}>
                {handles.map((handle) => (
                  <React.Fragment key={handle.id}>
                    <div
                      className={styles.thumb}
                      style={{ left: `${handle.percent}%` }}
                      {...getHandleProps(handle.id)}
                    />
                    <div
                      className={styles.thumbSvg}
                      style={{ left: `${handle.percent}%` }}
                      tabIndex={0}
                      role="slider"
                      aria-label="slider"
                      aria-valuemin={sliderProps.domain?.[0] ?? undefined}
                      aria-valuemax={sliderProps.domain?.[1] ?? undefined}
                      aria-valuenow={handle.value}
                    >
                      {!sliderProps.disabled ? <ThumbIcon /> : <DisabledThumbIcon />}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className={styles.tracks}>
                {tracks.map(({ id, source, target }) => (
                  <div
                    key={id}
                    className={styles.track}
                    style={{
                      left: `${source.percent}%`,
                      width: `${target.percent - source.percent}%`,
                    }}
                    {...getTrackProps()}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    </div>
  );
};

export default FilterRangeSlider;
