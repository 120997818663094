import React from 'react';
import ReactModal from 'react-modal';
import { autoUpdate, offset, size, useFloating } from '@floating-ui/react';

import IconChevronDown from '@/common/icons/tabler/chevron-down.svg';
import IconMapPin from '@/common/icons/tabler/map-pin.svg';
import IconX from '@/common/icons/tabler/x.svg';

import Button from '../Button/index.tsx';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  buttonText: string;
  panelHeading?: string;
  onClick?: () => void;
  buttonColour?: ButtonColour;
  buttonSize?: ButtonSize;
  ButtonOverride?: () => JSX.Element;
  PanelIcon?: () => JSX.Element;
  showPanelHeading?: boolean;
  panelPadding?: number;
  children: React.ReactNode;
}

const DropdownPanel = ({
  isOpen,
  onClick = () =>
    console.warn('<DropdownPanel/>: onClick() prop is missing, either supply the prop or override with ButtonOverride'),
  handleClose,
  buttonText,
  panelHeading,
  buttonColour = 'action',
  buttonSize = 'S',
  ButtonOverride,
  PanelIcon,
  showPanelHeading = true,
  panelPadding = 24,
  children,
}: Props) => {
  const { context, refs } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [
      offset(() => {
        return 8;
      }),
      {
        name: 'mobileDisable',
        fn() {
          if (window.innerWidth <= 768) {
            return {
              x: 0,
              y: 16,
            };
          }
          return {};
        },
      },
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  return (
    <div className={styles.dropdownPanel} ref={refs.setReference}>
      {ButtonOverride ? (
        <ButtonOverride />
      ) : (
        <Button
          size={buttonSize}
          style="solid"
          colour={buttonColour}
          styleOverride={styles.button}
          onClick={onClick}
          aria-label={buttonText}
        >
          <IconMapPin height={24} width={24} />
          <span data-show-only="desktop">{buttonText}</span>
          <IconChevronDown data-show-only="desktop" height={24} width={24} />
        </Button>
      )}
      <ReactModal
        overlayClassName={{
          base: styles.modal,
          afterOpen: styles.modalIsOpen,
          beforeClose: styles.modalClosing,
        }}
        className={styles.modalContent}
        isOpen={isOpen}
        onRequestClose={() => handleClose()}
        shouldCloseOnOverlayClick
        closeTimeoutMS={200}
        // This is to stop modal from disabling scroll
        bodyOpenClassName={''}
        ariaHideApp={false}
      >
        <div
          className={styles.panel}
          ref={refs.setFloating}
          style={{ ...context.floatingStyles, padding: panelPadding }}
        >
          {showPanelHeading && (
            <>
              <div className={styles.heading}>
                {!!PanelIcon && <PanelIcon />}
                {!!panelHeading && <p>{panelHeading}</p>}
                <Button
                  styleOverride={styles.closeButton}
                  style="solid"
                  size="S"
                  onClick={() => handleClose()}
                  data-testid="close-button"
                >
                  <IconX width={24} height={24} />
                </Button>
              </div>
              <hr />
            </>
          )}
          {children}
        </div>
      </ReactModal>
    </div>
  );
};

DropdownPanel.onClickWarning = '';

export default DropdownPanel;
