import React, { useId, useMemo } from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';
import { inflect } from 'inflection';
import { flatten } from 'lodash';
import { useRouter } from 'next/navigation.js';

import { getState } from '@/common/lib/AddressFormat.ts';
import { AUS } from '@/common/lib/Currency.ts';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import FilterTag from '../FilterTag/index.tsx';

import styles from './styles.module.scss';

type Props = {
  showResetButton?: boolean;
  isFilterMenu?: boolean;
  animateHeight?: boolean;
  filters: SearchDirectory.Filters;
};

const FilterTagBar = ({ showResetButton = true, isFilterMenu = false, animateHeight = true, filters = {} }: Props) => {
  const router = useRouter();

  const uid = useId();
  const totalFilters = StockSearch.getFilterActiveCount(filters);

  const renderFilters = useMemo(() => {
    const filterKeys = Object.keys(filters) as Array<keyof SearchDirectory.Filters>;
    const tags = filterKeys.map((key) => {
      switch (key) {
        case 'vehicleSearchQueries':
          const vehicleSearchQueries = filters.vehicleSearchQueries || [];
          return vehicleSearchQueries.map((query) => {
            const vehicleQueryText = [query.make, query.model, query.badge].filter((q): q is string => !!q).join(' ');
            const handleClick = () => {
              const updatedQueries = vehicleSearchQueries.filter((q) => {
                return !(query.badge
                  ? q.make === query.make && q.model === query.model && q.badge === query.badge
                  : query.model
                    ? q.make === query.make && q.model === query.model
                    : q.make === query.make);
              });

              router.push(
                StockSearch.buildURL({
                  ...filters,
                  vehicleSearchQueries: updatedQueries,
                  page: undefined,
                }),
              );
            };
            return (
              <FilterTag key={`${uid}-${key}-${vehicleQueryText}`} content={vehicleQueryText} onClose={handleClick} />
            );
          });
        case 'dealershipLocationQueries':
          const dealershipLocations = filters.dealershipLocationQueries || [];
          return dealershipLocations.map((query) => {
            const locationText = [query.state ? getState(query.state)?.short : '', query.city, query.suburb]
              .filter((q): q is string => !!q)
              .join(' ');
            const handleClick = () => {
              router.push(
                StockSearch.buildURL({
                  ...filters,
                  dealershipLocationQueries: StockSearch.nextDealershipLocationQuery(query, dealershipLocations),
                  page: undefined,
                }),
              );
            };
            return <FilterTag key={`${uid}-${key}-${locationText}`} content={locationText} onClose={handleClick} />;
          });
        case 'weeklyPriceRange':
        case 'seatsRange':
        case 'yearRange':
          const min = filters[key]?.min || 0;
          const max = filters[key]?.max;

          let text = '';
          switch (key) {
            case 'weeklyPriceRange':
              text = !max ? `${AUS.format(min)}+` : `${AUS.format(min)} - ${AUS.format(max)}`;
              break;
            case 'yearRange':
              text = `${min} - ${max}`;
              break;
            case 'seatsRange':
              text = `${min} - ${max} Seats`;
              break;
            default:
              break;
          }

          if (!text) break;

          const handleClick = () => {
            router.push(
              StockSearch.buildURL({
                ...filters,
                page: undefined,
                [key]: undefined,
              }),
            );
          };
          return <FilterTag key={`${uid}-${key}`} content={text} onClose={handleClick} />;
        case 'bodyTypes':
        case 'fuelTypes':
        case 'listingTypes':
        case 'driveTypes':
        case 'bodyTypes':
        case 'transmissions':
        case 'features':
          const values = filters[key] || [];
          return values.map((value) => (
            <FilterTag
              key={`${uid}-${key}-${value}`}
              content={value}
              onClose={() =>
                router.push(
                  StockSearch.buildURL({
                    ...filters,
                    [key]: values.filter((value2) => value2 != value),
                    page: undefined,
                  }),
                )
              }
            />
          ));
        case 'colours': {
          const values = filters[key] || [];
          return values.map((value) => (
            <div key={`${uid}-${key}-${value}`}>
              <FilterTag
                content={value}
                isColour
                onClose={() =>
                  router.push(
                    StockSearch.buildURL({
                      ...filters,
                      [key]: values.filter((value2) => value2 !== value),
                      page: undefined,
                    }),
                  )
                }
              />
            </div>
          ));
        }
        case 'search': {
          const { search, ...restFilters } = filters;
          const handleClick = () => {
            router.push(
              StockSearch.buildURL({
                ...restFilters,
                page: undefined,
              }),
            );
          };
          return <FilterTag key={`${uid}-${key}-${search}`} content={`"${search}"`} onClose={handleClick} />;
        }
        default:
          break;
      }
      return null;
    });

    return flatten(tags).filter((tag) => !!tag);
  }, [filters, router, uid]);

  return (
    <>
      <AnimateHeight height={!!totalFilters || !animateHeight ? 'auto' : 0} duration={600}>
        <div
          className={cc([
            styles.filterTagBar,
            { [styles.isFilterMenu]: isFilterMenu, [styles.closed]: !Object.keys(filters).length },
          ])}
          data-testid="filter-tag-bar"
        >
          {!!totalFilters && !!showResetButton && (
            <div className={styles.filterCount}>
              {totalFilters} {inflect('filter', totalFilters)}
              <button onClick={() => router.push(StockSearch.buildURL({}))}>Clear all</button>
            </div>
          )}
          <div className={styles.filters} data-testid="filter-tags">
            {renderFilters}
          </div>
        </div>
      </AnimateHeight>
    </>
  );
};

export default FilterTagBar;
