interface State {
  short: string;
  shortHandle: string;
  long: string;
  longHandle: string;
}

const States: Record<'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'WA' | 'VIC', State> = {
  ACT: {
    short: 'ACT',
    shortHandle: 'act',
    long: 'Australia Capital Territory',
    longHandle: 'australia-capital-territory',
  },
  NSW: {
    short: 'NSW',
    shortHandle: 'nsw',
    long: 'New South Whales',
    longHandle: 'new-south-whales',
  },
  NT: {
    short: 'NT',
    shortHandle: 'nt',
    long: 'Northern Territory',
    longHandle: 'northern-territory',
  },
  QLD: {
    short: 'QLD',
    shortHandle: 'qld',
    long: 'Queensland',
    longHandle: 'queensland',
  },
  SA: {
    short: 'SA',
    shortHandle: 'sa',
    long: 'South Australia',
    longHandle: 'south-australia',
  },
  TAS: {
    short: 'TAS',
    shortHandle: 'tas',
    long: 'Tasmania',
    longHandle: 'tasmania',
  },
  WA: {
    short: 'WA',
    shortHandle: 'wa',
    long: 'Western Australia',
    longHandle: 'western-australia',
  },
  VIC: {
    short: 'VIC',
    shortHandle: 'vic',
    long: 'Victoria',
    longHandle: 'victoria',
  },
};

export function getState(maybeState: string) {
  return Object.values(States).find((state) => Object.values(state).includes(maybeState));
}

export function addressSuggestionOnSearch(address: string) {
  return address.replace(', Australia', '');
}

export { States };
