import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/navigation.js';

import Button from '@/common/components/Button/index.tsx';
import DropdownPanel from '@/common/components/DropdownPanel/index.tsx';
import Checkbox from '@/common/components/fields/Checkbox/index.tsx';
import FieldValidationText from '@/common/components/fields/components/FieldValidationText/index.tsx';
import { addressSuggestionOnSearch } from '@/common/lib/AddressFormat.ts';
import {
  extractLatLngLiteral,
  getSaveDealershipAddressFromGeocoderAddressComponent,
} from '@/common/lib/GoogleGeocoderHelper.ts';
import { geocode } from '@/common/lib/GoogleMaps.ts';
import { query } from '@/listing-website/app/cars/actions.ts';
import CurrentLocation from '@/listing-website/components/SearchDirectory/components/CurrentLocation/index.tsx';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import FindLocation from '../FindLocation/index.tsx';

import styles from './styles.module.scss';

const SearchRadiusOptions = [5, 10, 25, 50] as const;

interface Props {
  initialOpen?: boolean;
  filters: SearchDirectory.Filters;
}

const parseRadius = (maybeRadius?: number | null): (typeof SearchRadiusOptions)[number] | null => {
  if (!maybeRadius || isNaN(Number(maybeRadius))) return null;
  const radius = Number(maybeRadius) / 1000;
  if (radius === 5 || radius === 10 || radius === 25 || radius === 50) return radius;
  return null;
};

const SetLocation = ({ initialOpen = false, filters }: Props) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [loading, setLoading] = useState(false);
  const [currentLocationLoading, setCurrentLocationLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [sortLocationCount, setSortLocationCount] = useState<number | null>(null);
  const [radius, setRadius] = useState<(typeof SearchRadiusOptions)[number] | null>(
    parseRadius(filters.dealershipGeo?.radius),
  );
  const [sortLocation, setSortLocation] = useState<GeoLocate | null>(filters.dealershipGeo || null);
  const [searchAddressValue, setSearchAddressValue] = useState<string>(filters.dealershipGeo?.streetAddress || '');
  const [geo, setGeo] = useState<google.maps.GeocoderResult | null>(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);

  // Add resizeObserver to handle dynamic height change of panel
  useEffect(() => {
    if (!contentRef) return;
    const resizeObserver = new ResizeObserver(() => setHeight(contentRef.clientHeight));
    resizeObserver.observe(contentRef);
    return () => resizeObserver.disconnect();
  }, [contentRef]);

  const reset = useCallback(() => {
    setSearchAddressValue('');
    setGeo(null);
    setRadius(null);
    setSortLocationCount(null);
    setSortLocation(null);
    setErrorMessage(null);
  }, []);

  const handleGeoResSelected = useCallback(
    (geoRes: google.maps.GeocoderResult) => {
      setSearchAddressValue(addressSuggestionOnSearch(geoRes.formatted_address));
      setGeo(geoRes);
    },
    [setSearchAddressValue],
  );

  const onLocationReturned = useCallback(
    (geocodeRes: google.maps.GeocoderResult | null) => {
      if (geocodeRes) {
        setErrorMessage(null);
        setRadius(null);
        setSortLocationCount(null);
        setSortLocation(null);
        setGeo(geocodeRes);
        // Need to build string using addressParts; otherwise could include street / street number etc
        const adp = getSaveDealershipAddressFromGeocoderAddressComponent(geocodeRes);
        setSearchAddressValue([adp.suburb, adp.state, adp.postCode].filter((f) => f).join(' '));
      } else {
        reset();
        setErrorMessage(SetLocation.geoLocationErrorMsg);
      }
      setCurrentLocationLoading(false);
    },
    [reset],
  );

  const clearLocation = () => {
    // Reset fields
    reset();
    router.push(StockSearch.buildURL({ ...filters, page: undefined, dealershipGeo: undefined }));
  };

  const getSearchLocation = useCallback(
    async (radius: (typeof SearchRadiusOptions)[number]) => {
      setLoading(true);
      setSortLocation(null);
      setSortLocationCount(null);
      setErrorMessage(null);

      // if geo isn't set, should attempt to get geocode results based on searchAddressValue
      // this should only occur if SSR sortLocation was applied
      let geoLoc = geo;
      if (!geoLoc && searchAddressValue) {
        geoLoc = (await geocode({ address: searchAddressValue }))?.[0] ?? null;
      }

      if (geoLoc) {
        const { lat, lng } = extractLatLngLiteral(geoLoc);

        const data = await query({
          ...filters,
          dealershipGeo: { latitude: lat, longitude: lng, radius: radius * 1000, streetAddress: searchAddressValue },
        });

        if (!!data?.searchStock.total) {
          setSortLocation({ latitude: lat, longitude: lng, radius: radius * 1000, streetAddress: searchAddressValue });
          setSortLocationCount(data.searchStock.total);
        } else {
          setErrorMessage(SetLocation.noStockError);
        }
      }

      setLoading(false);
    },
    [filters, geo, searchAddressValue],
  );

  const onRadiusSelected = useCallback(
    (value: (typeof SearchRadiusOptions)[number] | null) => {
      setRadius(value);
      if (!!value) getSearchLocation(value);
    },
    [getSearchLocation],
  );

  const handleSearchAddressChange = useCallback(
    (res: google.maps.GeocoderResult | null) => {
      setErrorMessage(null);

      if (res) {
        handleGeoResSelected(res);
      } else {
        reset();
      }
    },
    [handleGeoResSelected, reset],
  );

  const handleSubmit = useCallback(async () => {
    setIsOpen(false);
    router.push(StockSearch.buildURL({ ...filters, page: undefined, dealershipGeo: sortLocation }));
  }, [filters, router, sortLocation]);

  const buttonText = useMemo(() => {
    if (loading) {
      return 'Loading';
    }

    return sortLocation && sortLocationCount ? `Show ${sortLocationCount.toLocaleString()} cars` : 'Show cars';
  }, [loading, sortLocation, sortLocationCount]);

  return (
    <DropdownPanel
      isOpen={isOpen}
      onClick={() => setIsOpen(true)}
      handleClose={() => setIsOpen(false)}
      buttonText={filters.dealershipGeo?.streetAddress || SetLocation.buttonText}
      buttonColour="action-light"
      buttonSize="L"
      showPanelHeading={false}
      panelPadding={0}
    >
      <div className={styles.setLocation} style={{ height }} data-testid="set-location-modal">
        <div ref={setContentRef} className={styles.content}>
          <FindLocation
            value={searchAddressValue}
            onChange={handleSearchAddressChange}
            onError={(error) => setErrorMessage(error)}
            disabled={currentLocationLoading}
          />
          <div className={styles.container}>
            {!geo && (
              <CurrentLocation
                onLocationReturned={onLocationReturned}
                onClick={() => setCurrentLocationLoading(true)}
              />
            )}
            {!!searchAddressValue && <SearchRadius value={radius} onChange={onRadiusSelected} />}
            {errorMessage && <FieldValidationText text={errorMessage} severity="error" />}
            {sortLocation && (
              <Button
                styleOverride={styles.button}
                onClick={handleSubmit}
                disabled={loading}
                data-testid="submit-button"
                style="solid"
              >
                {buttonText}
              </Button>
            )}
          </div>
          {!!searchAddressValue && (
            <button
              type="button"
              onClick={() => clearLocation()}
              className={styles.clearLocation}
              data-testid="clear-button"
            >
              Clear Location
            </button>
          )}
        </div>
      </div>
    </DropdownPanel>
  );
};

SetLocation.buttonText = 'Set your location';
SetLocation.geoLocationErrorMsg = 'Can not get your location. Please try to use the input.';
SetLocation.noStockError = `We don't have stock near that post code yet.`;
SetLocation.searchErrorMsg = 'Please enter in a suburb or postcode';
SetLocation.searchRadiusOptions = SearchRadiusOptions;

const SearchRadius = ({
  value,
  onChange,
}: {
  value: (typeof SearchRadiusOptions)[number] | null;
  onChange: (value: (typeof SearchRadiusOptions)[number] | null) => void;
}) => {
  return (
    <div className={styles.searchRadius} data-testid="search-radius">
      <p className={styles.title}>Radius</p>
      {SearchRadiusOptions.map((opt) => (
        <Checkbox
          name="radius"
          key={opt}
          label={`${opt}km`}
          onChange={() => opt !== value && onChange(opt)}
          checked={value === opt}
          data-checked={value === opt}
          data-testid="radius-option"
        />
      ))}
    </div>
  );
};

export default SetLocation;
