'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { useDebouncedCallback } from 'use-debounce';

import FieldValidationText from '@/common/components/fields/components/FieldValidationText/index.tsx';
import TextField from '@/common/components/fields/TextField/index.tsx';
import { addressSuggestionOnSearch } from '@/common/lib/AddressFormat.ts';
import { geocode, getPlacePredictions } from '@/common/lib/GoogleMaps.ts';

import IconSearch from '@/common/icons/tabler/search.svg';

import styles from './styles.module.scss';

interface FindLocationProps {
  value: null | string;
  onChange: (res: google.maps.GeocoderResult | null) => void;
  onError: (error: string) => void;
  severity?: FieldSeverity;
  validationText?: string;
  disabled?: boolean;
}

const FindLocation = ({ value, onChange, onError, severity, validationText, disabled }: FindLocationProps) => {
  const [internalValue, setInternalValue] = useState(value ?? '');
  const [suggestions, setSuggestions] = useState<Array<google.maps.places.AutocompletePrediction>>([]);
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(false);

  const resultsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  const getSuggestions = useDebouncedCallback(async (value: string) => {
    const results = await getPlacePredictions({ input: value }, ['postal_code', 'locality']);
    if (results?.length) {
      setSuggestions(results);
      setNoResults(false);
    } else {
      setSuggestions([]);
      setNoResults(true);
    }
    setLoading(false);
  }, 700);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      if (suggestions.length) setSuggestions([]);
      if (noResults) setNoResults(false);

      const newValue = event.target.value;
      setInternalValue(newValue);

      if (newValue) {
        setLoading(true);
        getSuggestions(newValue);
      } else {
        getSuggestions.cancel();
        setLoading(false);
        onChange(null);
      }
    },
    [getSuggestions, noResults, onChange, suggestions.length],
  );

  const handleSuggestionOnClick = useCallback(
    async (suggestion: google.maps.places.AutocompletePrediction) => {
      try {
        const res = (await geocode({ placeId: suggestion.place_id, componentRestrictions: undefined }))?.[0];
        if (res) {
          onChange(res);
          setInternalValue('');
          setSuggestions([]);
        } else {
          onError(FindLocation.geocodeError);
        }
      } catch (error) {
        onError(FindLocation.geocodeError);
      }
    },
    [onChange, onError],
  );

  return (
    <div className={styles.searchAddress}>
      <div className={styles.input}>
        <TextField
          name="address"
          label="Find by suburb or postcode"
          placeholder="Suburb or postcode"
          IconRight={loading ? Spinner : IconSearch}
          value={internalValue}
          onChange={handleOnChange}
          onFocus={(e) => value && e.target.select()}
          autoComplete="off"
          severity={severity}
          disabled={disabled}
        />
      </div>
      {(!value || internalValue) && (
        <div className={styles.results} ref={resultsRef}>
          {noResults && <span className={styles.noResults}>{FindLocation.noResultsString}</span>}
          {suggestions.map((s) => (
            <button
              key={s.place_id}
              type="button"
              className={styles.suggestions}
              onClick={() => handleSuggestionOnClick(s)}
              data-testid="suggestion-button"
            >
              {addressSuggestionOnSearch(s.description)}
            </button>
          ))}
        </div>
      )}
      {!!validationText && <FieldValidationText text={validationText} severity={severity} />}
    </div>
  );
};

FindLocation.noResultsString = 'No results found';
FindLocation.geocodeError = 'Failed to get address, please try again';

export default FindLocation;
