import React, { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';

import { geocode } from '@/common/lib/GoogleMaps.ts';

import IconLocation from '@/common/icons/tabler/location.svg';

import styles from './styles.module.scss';

const Spinner = dynamic(() => import('@atlaskit/spinner'), { ssr: false });

interface Props {
  onLocationReturned: (geocoderResult: google.maps.GeocoderResult | null) => void;
  onClick?: () => void;
}

const CurrentLocation = ({ onLocationReturned, onClick }: Props) => {
  const [loading, setLoading] = useState(false);
  const successCallback = useCallback(
    async (position: GeolocationPosition) => {
      if (position.coords.latitude && position.coords.longitude) {
        const res = await geocode({
          location: { lat: position.coords.latitude, lng: position.coords.longitude },
          componentRestrictions: undefined,
        });
        if (res) {
          setLoading(false);
          return onLocationReturned(res[0]);
        }
      }
      setLoading(false);
      return onLocationReturned(null);
    },
    [onLocationReturned],
  );

  const errorCallback = useCallback(() => {
    onLocationReturned(null);
    setLoading(false);
  }, [onLocationReturned]);

  const handleCurrentLocationClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.();
      setLoading(true);
      event.preventDefault();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      } else {
        setLoading(false);
      }
    },
    [errorCallback, onClick, successCallback],
  );

  return (
    <button disabled={loading} onClick={handleCurrentLocationClick} className={styles.currentLocation}>
      {loading ? <Spinner /> : <IconLocation />}
      Use my current location
    </button>
  );
};

export default CurrentLocation;
