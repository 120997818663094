'use client';

import React, { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { orderBy } from 'lodash';

import Breadcrumbs, { Crumb } from '@/common/components/Breadcrumbs/index.tsx';
import FilterButton from '@/common/components/FilterButton/index.tsx';
import FilterDropdown from '@/common/components/FilterDropdown/index.tsx';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import IconCar from '@/common/icons/tabler/car.svg';

import styles from './styles.module.scss';

interface Props {
  defaultOpen?: boolean;
  makes: Array<SearchFilterOptionMake>;
  vehicleSearchQueries: Array<VehicleSearchQuery>;
  onChange: (vehicleSearchQueries: Array<VehicleSearchQuery>) => void;
}

const MakeModelFilter = ({ defaultOpen = false, makes, vehicleSearchQueries = [], onChange }: Props) => {
  const [showAllMakes, setShowAllMakes] = useState(defaultOpen);
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');

  // Clear selectedMake if make filter is removed
  useEffect(() => {
    if (selectedMake && !vehicleSearchQueries?.some((x) => x.make === selectedMake)) {
      setSelectedMake('');
    }
  }, [selectedMake, vehicleSearchQueries]);

  const filterOptions = useMemo(() => {
    if (selectedMake && !selectedModel) {
      return makes.find((make) => make.name === selectedMake)?.models || [];
    } else if (selectedMake && selectedModel) {
      return (
        makes.find((make) => make.name === selectedMake)?.models?.find((model) => model.name === selectedModel)
          ?.badges || []
      );
    } else {
      return StockSearch.getPopularMakes(makes);
    }
  }, [makes, selectedMake, selectedModel]);

  const handleSelect = (option: SearchDirectory.VehicleFilterOption) => {
    switch (option.__typename) {
      case 'SearchFilterOptionMake':
        onChange(StockSearch.nextVehicleSearchQuery({ make: option.name }, vehicleSearchQueries));
        break;
      case 'SearchFilterOptionModel':
        onChange(StockSearch.nextVehicleSearchQuery({ make: selectedMake, model: option.name }, vehicleSearchQueries));
        break;
      case 'SearchFilterOptionBadge':
        onChange(
          StockSearch.nextVehicleSearchQuery(
            { make: selectedMake, model: selectedModel, badge: option.name },
            vehicleSearchQueries,
          ),
        );
        break;
      default:
        break;
    }
  };

  const breadcrumbs = useMemo(() => {
    const crumbs: Crumb[] = showAllMakes
      ? [{ text: 'All Makes', onClick: () => setSelectedMake('') }]
      : [{ text: 'Popular makes', onClick: () => setSelectedMake('') }];

    if (selectedMake) {
      if (selectedModel) {
        crumbs.push({ text: 'Models', onClick: () => setSelectedModel('') });
        crumbs.push({ text: 'Badges' });
      } else {
        crumbs.push({ text: 'Models', onClick: () => setSelectedModel('') });
      }
    }

    return crumbs;
  }, [selectedMake, showAllMakes, selectedModel]);

  const isActive = (option: SearchDirectory.VehicleFilterOption) => {
    switch (option.__typename) {
      case 'SearchFilterOptionMake':
        return vehicleSearchQueries?.some((query) => query.make === option.name) || false;
      case 'SearchFilterOptionModel':
        return (
          vehicleSearchQueries?.some((query) => query.make === selectedMake && query.model === option.name) || false
        );
      case 'SearchFilterOptionBadge':
        return (
          vehicleSearchQueries?.some(
            (query) => query.make === selectedMake && query.model === selectedModel && query.badge === option.name,
          ) || false
        );
      default:
        return false;
    }
  };

  return (
    <div className={styles.makeModelFilter} data-testid="make-model-filter-section">
      <FilterDropdown
        heading="Make and model"
        Icon={() => <IconCar height={24} width={24} />}
        defaultOpen={!!makes.length}
        countActive={vehicleSearchQueries?.length}
      >
        <div className={styles.filters}>
          <div className={styles.breadcrumbs}>
            <Breadcrumbs showHome={false} crumbs={breadcrumbs} />
          </div>
          <AnimateHeight height={!showAllMakes || selectedMake ? 'auto' : 0} duration={200}>
            <div className={styles.filters}>
              {filterOptions?.map((option) => {
                switch (option.__typename) {
                  case 'SearchFilterOptionMake':
                    return (
                      <FilterButton
                        key={option.name}
                        label={`${option.name.toString()} (${option.total})`}
                        onClick={() => handleSelect(option)}
                        checked={isActive(option)}
                        checkedButtonText="Select model"
                        checkedButtonOnClick={() => setSelectedMake(option.name)}
                      />
                    );
                  case 'SearchFilterOptionModel':
                    return (
                      <FilterButton
                        key={option.name}
                        label={`${option.name.toString()} (${option.total})`}
                        onClick={() => handleSelect(option)}
                        checked={isActive(option)}
                        checkedButtonText="Select badge"
                        checkedButtonOnClick={() => setSelectedModel(option.name)}
                      />
                    );
                  case 'SearchFilterOptionBadge':
                    return (
                      <FilterButton
                        key={option.name}
                        label={`${option.name.toString()} (${option.total})`}
                        onClick={() => handleSelect(option)}
                        checked={isActive(option)}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </AnimateHeight>

          {/* "Show All Makes" expand, should only be visible if no filter options / filter selected */}
          {!selectedMake && (
            <>
              <AnimateHeight height={showAllMakes ? 'auto' : 0} duration={200}>
                {/* Only render if show all makes has been selected */}
                {showAllMakes && (
                  <div className={styles.filters}>
                    {orderBy(makes, 'handle').map((option) => (
                      <FilterButton
                        key={option.name}
                        label={`${option.name.toString()} (${option.total})`}
                        onClick={() => handleSelect(option)}
                        checked={isActive(option)}
                        checkedButtonText="Select model"
                        checkedButtonOnClick={() => setSelectedMake(option.name)}
                      />
                    ))}
                  </div>
                )}
              </AnimateHeight>
              <button className={styles.showAllMakes} onClick={() => setShowAllMakes(!showAllMakes)}>
                {!showAllMakes ? MakeModelFilter.showAllMakesText : MakeModelFilter.showLessMakesText}
              </button>
            </>
          )}
        </div>
      </FilterDropdown>
    </div>
  );
};

MakeModelFilter.showAllMakesText = 'Show all makes';
MakeModelFilter.showLessMakesText = 'Show popular makes';

export default MakeModelFilter;
