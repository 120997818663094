import React from 'react';
import { useRouter } from 'next/navigation.js';

import AutoComplete from '@/common/components/AutoComplete/index.tsx';
import SearchWordHighlight from '@/common/lib/SearchWordHighlight';
import StockSearch from '@/listing-website/lib/StockSearch';

import { getAutocompleteSearch } from './actions';

interface Props {
  filters: SearchDirectory.Filters;
}

const Search = ({ filters }: Props) => {
  const router = useRouter();
  const route = (newFilters: SearchDirectory.Filters) => {
    router.push(StockSearch.buildURL({ ...filters, ...newFilters, page: undefined }));
  };

  const handleSuggestionsRequested = async (value: string) => {
    const autocompleteResults = await getAutocompleteSearch(value);
    return [...autocompleteResults, { match: value, type: 'Keyword' }];
  };

  const handleSuggestionsSelected = (suggestion: SearchAutocompleteResult) => {
    switch (suggestion.type) {
      case 'Make':
        route({
          vehicleSearchQueries: StockSearch.nextVehicleSearchQuery(
            { make: suggestion.match },
            filters.vehicleSearchQueries || [],
          ),
        });
        break;
      case 'MakeModel':
        const [make, model] = suggestion.extras || [];
        if (!make || !model) break;
        route({
          vehicleSearchQueries: StockSearch.nextVehicleSearchQuery({ make, model }, filters.vehicleSearchQueries || []),
        });
        break;
      case 'GenericColour':
        route({ colours: [suggestion.match] });
        break;
      case 'Feature':
        route({ features: [suggestion.match] });
        break;
      case 'Keyword':
        route({ search: suggestion.match });
        break;
      default:
        break;
    }
  };

  const highlight = (match: string, searchValue: string) => {
    if (!searchValue) return match;
    const { chains, startingIndex } = SearchWordHighlight(match, searchValue.toLowerCase());
    return chains.map((chain, i) => (
      <span key={i}>{i % 2 === startingIndex ? <strong>{chain}</strong> : <>{chain}</>}</span>
    ));
  };

  const renderSuggestion = (suggestion: SearchAutocompleteResult, inputValue: string) => {
    switch (suggestion.type) {
      case 'Make':
      case 'MakeModel':
        return <>{highlight(suggestion.match, inputValue)}</>;
      case 'GenericColour':
        return <>Cars That Are {highlight(suggestion.match, inputValue)}</>;
      case 'Feature':
        return <>Cars With {highlight(suggestion.match, inputValue)}</>;
      case 'Keyword':
        return <>Keyword: {highlight(suggestion.match, inputValue)}</>;
      default:
        return null;
    }
  };

  return (
    <AutoComplete<SearchAutocompleteResult>
      placeholder={Search.placeholder}
      suggestionResultsHeading={Search.resultsHeading}
      onSuggestionsRequested={handleSuggestionsRequested}
      onSuggestionSelected={handleSuggestionsSelected}
      getSuggestionValue={(suggestion) => suggestion.match}
      renderSuggestion={renderSuggestion}
    />
  );
};

Search.placeholder = 'Search makes, models. keywords';
Search.resultsHeading = 'Search Suggestions';

export default Search;
