import React, { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/navigation';

import DropdownPanel from '@/common/components/DropdownPanel/index.tsx';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import IconArrowsSort from '@/common/icons/tabler/arrows-sort.svg';
import IconCheck from '@/common/icons/tabler/check.svg';
import IconChevronDown from '@/common/icons/tabler/chevron-down.svg';

import styles from './styles.module.scss';

interface SortOption {
  title: string;
  sortBy: string;
  sortByOrder: 'ASC' | 'DESC';
}

const sortOptions: Array<SortOption> = [
  // { title: 'Featured', sortBy: 'recommended' },
  { title: 'Just added', sortBy: 'datePublished', sortByOrder: 'DESC' as const },
  { title: 'Lowest price', sortBy: 'price', sortByOrder: 'ASC' as const },
  { title: 'Highest price', sortBy: 'price', sortByOrder: 'DESC' as const },
  { title: 'Lowest kms', sortBy: 'mileage', sortByOrder: 'ASC' as const },
  { title: 'Highest kms', sortBy: 'mileage', sortByOrder: 'DESC' as const },
  { title: 'Newest age', sortBy: 'year', sortByOrder: 'DESC' as const },
  { title: 'Oldest age', sortBy: 'year', sortByOrder: 'ASC' as const },
];

interface Props {
  filters: SearchDirectory.Filters;
}

const SetSortBy = ({ filters }: Props) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const isActive = useCallback(
    (option: SortOption) => filters.sortBy === option.sortBy && filters.sortByOrder === option.sortByOrder,
    [filters.sortBy, filters.sortByOrder],
  );

  const sortByButton = useMemo(
    () => (
      <button onClick={() => setIsOpen(true)} className={styles.button} data-testid="sortby-button">
        {/* Mobile */}
        <IconArrowsSort width={24} height={24} data-hide-only="desktop" />
        <p data-hide-only="desktop">Sort</p>
        {/* Desktop */}
        <p data-show-only="desktop">Sort by</p>
        <div className={styles.optionBox} data-show-only="desktop">
          <span>
            {sortOptions.find(isActive)?.title ?? sortOptions[0].title}
            <IconChevronDown height={24} width={24} data-show-only="desktop" />
          </span>
        </div>
      </button>
    ),
    [isActive],
  );

  return (
    <DropdownPanel
      isOpen={isOpen}
      ButtonOverride={() => sortByButton}
      handleClose={() => setIsOpen(false)}
      buttonText="Sort By"
      panelHeading="Sort By"
      PanelIcon={() => <IconArrowsSort height={24} width={24} />}
    >
      <div className={styles.setSortBy} data-testid="sortby-options">
        {sortOptions.map((option) => (
          <button
            key={option.title}
            onClick={() => {
              router.push(
                StockSearch.buildURL({
                  ...filters,
                  page: undefined,
                  sortBy: option.sortBy,
                  sortByOrder: StockSearch.parseSortByOrder(option.sortByOrder),
                }),
              );
              setIsOpen(false);
            }}
            className={isActive(option) ? styles.active : undefined}
            data-testid={`sortby-option-${option.title}`}
          >
            {option.title}
            {isActive(option) && <IconCheck height={24} width={24} />}
          </button>
        ))}
      </div>
    </DropdownPanel>
  );
};

export default SetSortBy;
