import React from 'react';
import cc from 'classcat';

import CheckboxChecked from '@/common/icons/checkbox-checked-S.svg';
import CheckboxDefault from '@/common/icons/checkbox-default.svg';

import styles from './styles.module.scss';

interface CheckboxProps extends BaseFieldComponentProps<string, 'input'> {
  checked?: boolean;
  CheckedIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  UncheckedIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  /** If supplied, will render instead of label  */
  children?: React.ReactNode;
}

const Checkbox = ({
  name,
  value,
  label,
  checked,
  disabled,
  children,
  CheckedIcon = () => <CheckboxChecked />,
  UncheckedIcon = () => <CheckboxDefault />,
  ...htmlInputProps
}: CheckboxProps) => {
  return (
    <label className={cc([styles.checkbox, checked && styles.checked, disabled && styles.disabled])}>
      {!children && !!label && <p>{label}</p>}
      {!!children && <div>{children}</div>}
      <span className={styles.icon}>
        <input
          type="checkbox"
          name={name}
          value={value}
          className={styles.input}
          defaultChecked={checked}
          disabled={disabled}
          {...htmlInputProps}
        />
        {/* SVGS display based on :checked CSS */}
        <span className={styles.checked}>
          <CheckedIcon />
        </span>
        <span className={styles.unchecked}>
          <UncheckedIcon />
        </span>
      </span>
    </label>
  );
};

export default Checkbox;
