import React from 'react';
import cc from 'classcat';

import Close from '@/common/icons/tabler/x.svg';

import styles from './styles.module.scss';

type Props = {
  content: string;
  onClose: () => void;
  isColour?: boolean;
};

const FilterTag = ({ content, onClose, isColour }: Props) => {
  return (
    <div
      className={cc([styles.filterTag, styles.outlined, styles.button, styles.colourTag])}
      onClick={() => onClose()}
      onKeyDown={(e) => e.key === 'Enter' && onClose()}
      tabIndex={0}
      role="button"
    >
      {content} {isColour && <span className={styles[content?.toString().toLowerCase()]} />}
      <div className={styles.deleteIcon}>
        <Close />
      </div>
    </div>
  );
};

export default FilterTag;
