import React from 'react';

import IconHome from '@/common/icons/tabler/home.svg';
import IconSlash from '@/common/icons/tabler/slash.svg';

import styles from './styles.module.scss';

interface Crumb {
  text: string;
  link?: string;
  onClick?: () => void;
}

interface BreadcrumbsProps {
  crumbs: Array<Crumb>;
  showHome?: boolean;
  icon?: React.ReactNode;
}

const Breadcrumbs = ({ crumbs, showHome = true, icon }: BreadcrumbsProps) => {
  return (
    <nav className={styles.breadcrumbs} aria-label="Breadcrumb">
      {!!showHome && (
        <div className={styles.crumb}>
          <a href="/cars" className={styles.home} aria-label="Home">
            <IconHome height={16} width={16} />
          </a>
          <IconSlash />
        </div>
      )}
      {crumbs.map((crumb, index) => {
        const RootElem = crumb.onClick ? 'button' : 'a';
        return (
          <div key={index} className={styles.crumb} data-testid="crumbItem">
            {(crumb.link || crumb.onClick) && index !== crumbs.length - 1 ? (
              <RootElem href={crumb.link?.toLowerCase()} onClick={crumb.onClick}>
                {crumb.text}
              </RootElem>
            ) : (
              <span aria-current={index === crumbs.length - 1 ? 'page' : undefined}>{crumb.text}</span>
            )}
            {index !== crumbs.length - 1 && (icon || <IconSlash />)}
          </div>
        );
      })}
    </nav>
  );
};

export default Breadcrumbs;
export type { Crumb };
