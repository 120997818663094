import React from 'react';

import Button from '@/common/components/Button/index.tsx';

import styles from './styles.module.scss';

interface Props {
  logo?: React.ReactElement;
  heading?: string;
  body?: string;
  buttonText?: string;
  link: string;
}

const PromotionTileCard = ({ logo, heading, body, buttonText, link }: Props) => {
  return (
    <div className={styles.promotionTileWrapper}>
      <div className={styles.promotionTile}>
        {logo && <div>{logo}</div>}
        <div className={styles.promotionTileContent}>
          <div className={styles.promotionTileText}>
            {!!heading && <p className={styles.heading}>{heading}</p>}
            {!!body && <p className={styles.text}>{body}</p>}
          </div>
          {!!buttonText && (
            <Button colour="on-brand" style="solid" size="L" href={link}>
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromotionTileCard;
