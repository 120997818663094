import React, { useEffect, useId, useState } from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';

import IconChevronDown from '@/common/icons/tabler/chevron-down.svg';

import styles from './styles.module.scss';

export interface FilterDropdownProps {
  heading: string | (() => JSX.Element);
  children: React.ReactNode;
  Icon?: () => JSX.Element;
  defaultOpen?: boolean;
  countActive?: number;
  styleOverride?: {
    filterDropDown?: string;
    heading?: string;
    content?: string;
  };
}

export default function FilterDropdown({
  heading,
  children,
  Icon,
  defaultOpen = false,
  countActive = 0,
  styleOverride,
}: FilterDropdownProps) {
  const [open, setOpen] = useState(defaultOpen);
  const [isRenderingContent, setIsRenderingContent] = useState(defaultOpen);
  const [openedByDefault, setOpenedByDefault] = useState(defaultOpen);
  const uid = useId();

  useEffect(() => {
    if (openedByDefault || !defaultOpen) {
      // if the dropdown has already been opened by default -> defaultOpen will not affect component state anymore
      return;
    } else {
      // if the dropdown hasn't been opened yet and defaultOpen is true -> set everything to true.
      setOpen(true);
      setIsRenderingContent(true);
      setOpenedByDefault(true);
    }
  }, [defaultOpen, openedByDefault]);

  return (
    <div className={cc([styles.filterDropdown, styleOverride?.filterDropDown])}>
      <button
        aria-expanded={open}
        aria-controls={`dropdown-${uid}`}
        onClick={() => {
          // Interacting with button should cause content to render
          setIsRenderingContent(true);
          // Set AnimateHeight open/close state
          setOpen(!open);
        }}
        className={cc({ [styles.top]: true, [styles.open]: open })}
      >
        {!!Icon && (
          <div className={styles.left} data-testid="icon">
            <Icon />
          </div>
        )}
        <div className={cc([styles.heading, styleOverride?.heading])}>
          {typeof heading === 'function' ? heading() : heading}
        </div>
        <div className={styles.right}>
          {countActive > 0 && <span className={styles.count}>{countActive}</span>}
          <IconChevronDown width={24} height={24} />
        </div>
      </button>
      <AnimateHeight
        id={`dropdown-${uid}`}
        height={open ? 'auto' : 0}
        duration={200}
        onHeightAnimationEnd={() => {
          // After animation, if closed, stop rendering content
          if (!open) setIsRenderingContent(false);
        }}
      >
        {isRenderingContent && <div className={cc([styles.content, styleOverride?.content])}>{children}</div>}
      </AnimateHeight>
    </div>
  );
}
