import React from 'react';

import Toggle from '@/common/components/Toggle/index.tsx';

import styles from './styles.module.scss';

interface Props {
  label: string;
  toggled: boolean;
  onToggle: (toggled: boolean) => void;
  Icon?: () => JSX.Element;
  modalContent?: string;
  modalButtonIcon?: JSX.Element;
}

const FilterToggle = ({ label, toggled, Icon, onToggle }: Props) => {
  return (
    <div className={styles.filterToggle}>
      {!!Icon && <Icon />}
      <p className={styles.label}>{label}</p>
      <div className={styles.right}>
        <Toggle toggled={toggled} onClick={onToggle} />
      </div>
    </div>
  );
};

export default FilterToggle;
