/**
 * Provides SaveDealershipAddress object from GeocoderAddressComponent
 * @param address_components Address Component from google maps geocoder result
 */

// Convert GeocoderResult to SaveDealershipAddressInput
export const getSaveDealershipAddressFromGeocoderAddressComponent = (
  result: google.maps.GeocoderResult,
): SaveDealershipAddressInput => {
  // Adding street number to street address
  const streetNumber = result.address_components.find((c) => c.types.includes('street_number'))?.long_name || '';
  const streetAddress = result.address_components.find((c) => c.types.includes('route'))?.long_name || '';
  const fullAddress = !!streetNumber ? `${streetNumber} ${streetAddress}` : `${streetAddress}`;

  return {
    streetAddress: fullAddress,
    suburb: result.address_components.find((c) => c.types.includes('locality'))?.long_name || '',
    city: result.address_components.find((c) => c.types.includes('locality'))?.long_name ?? '',
    state: result.address_components.find((c) => c.types.includes('administrative_area_level_1'))?.short_name || '',
    postCode: parseInt(result.address_components.find((c) => c.types.includes('postal_code'))?.long_name || '0', 10),
    latitude: result.geometry.location.lat(),
    longitude: result.geometry.location.lng(),
  };
};

export const getAddressStringFromSaveDealershipAddress = (address: SaveDealershipAddressInput) =>
  [address.streetAddress ? address.streetAddress + ',' : '', address.suburb, address.state]
    .filter((f) => !!f)
    .join(' ');

/**
 * Google API returns different results clientside vs serverside. Specifically latlng values.
 * This forces latlng to return as literal values.
 */
export function extractLatLngLiteral(geocoderResult: google.maps.GeocoderResult): google.maps.LatLngLiteral {
  const location = geocoderResult.geometry.location;
  if (typeof location.lat === 'function' && typeof location.lng === 'function') {
    return { lat: location.lat(), lng: location.lng() };
  } else {
    return { lat: Number(location.lat), lng: Number(location.lng) };
  }
}
