import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import FilterRangeSlider from '../FilterRangeSlider/index.tsx';

import styles from './styles.module.scss';

export interface BaseRangeFilterProps {
  domain: readonly number[];
  values: number[];
  // Occurs after debounce, when value is set
  onChange: (values: readonly number[]) => void;
  // Occurs on every update; text input or slider change
  onUpdate?: (values: readonly number[]) => void;
  minPlaceholder?: string;
  maxPlaceholder?: string;
  minLabel?: string;
  maxLabel?: string;
  step?: number;
  disabled?: boolean;
  showMaxPlaceholderAtMaxValue?: boolean;
  InputIcon?: () => JSX.Element;
}

const BaseRangeFilter = ({
  domain,
  values,
  onChange,
  onUpdate,
  minPlaceholder,
  maxPlaceholder,
  showMaxPlaceholderAtMaxValue = false,
  minLabel = 'From',
  maxLabel = 'To',
  step = 1,
  disabled = false,
  InputIcon,
}: BaseRangeFilterProps) => {
  const [inputMinMax, setInputMinMax] = useState(['', '']);

  // Set input value to empty if domain and value match
  useEffect(() => {
    if (isEqual(values, domain)) {
      setInputMinMax(['', '']);
    } else {
      const newMax =
        showMaxPlaceholderAtMaxValue && values[1] >= domain[1] && !!maxPlaceholder ? '' : values[1].toString();
      setInputMinMax([values[0].toString(), newMax]);
    }
  }, [values, domain, showMaxPlaceholderAtMaxValue, maxPlaceholder]);

  // Internal onChange / onUpdate keeps inputValue matching values
  const _onChange = (values: readonly number[]) => {
    let min = !isNaN(values[0]) ? values[0] : domain[0];
    let max = !isNaN(values[1]) ? values[1] : domain[1];
    if (step !== 1) {
      min = Math.round(min / step) * step;
      max = Math.round(max / step) * step;
    }
    onChange([min, max]);
  };
  const _onUpdate = (values: readonly number[]) => {
    if (isEqual(values, domain)) {
      setInputMinMax(['', '']);
    } else if (values[0].toString() === inputMinMax[0] && inputMinMax[1] === '' && values[1] === domain[1]) {
      // stop update function being called when there is no change in values but '' to the maximum value
      return;
    } else {
      const newMax =
        showMaxPlaceholderAtMaxValue && values[1] >= domain[1] && !!maxPlaceholder ? '' : values[1].toString();
      setInputMinMax([values[0].toString(), newMax]);
    }
    if (onUpdate) {
      let min = !isNaN(values[0]) ? values[0] : domain[0];
      let max = !isNaN(values[1]) ? values[1] : domain[1];
      if (step !== 1) {
        min = Math.round(min / step) * step;
        max = Math.round(max / step) * step;
      }
      onUpdate([min, max]);
    }
  };

  const debounceInputFilterChange = useDebouncedCallback((input: 'min' | 'max', value: number) => {
    // Check to make sure min and max don't cross each other; or fall outside domain
    let limitedVal: number;
    if (value < domain[0]) {
      limitedVal = domain[0];
    } else if (value > domain[1]) {
      limitedVal = domain[1];
    } else {
      limitedVal = value;
    }
    _onChange(input === 'min' ? [limitedVal, values[1]] : [values[0], limitedVal]);
  }, 1200);

  const handleInputChange = (input: 'min' | 'max', value: string) => {
    setInputMinMax(input === 'min' ? [value, inputMinMax[1]] : [inputMinMax[0], value]);
    const parsedVal = parseInt(value, 10);

    // Return if invalid value (typically empty input)
    if (isNaN(parsedVal)) return;
    debounceInputFilterChange(input, parsedVal);
  };

  const debounceSliderFilterChange = useDebouncedCallback((values: readonly number[]) => {
    _onChange(values as number[]);
  }, 700);

  const handleSliderChange = (values: readonly number[]) => {
    debounceSliderFilterChange(values);
  };

  const handleSliderUpdate = (values: readonly number[]) => {
    _onUpdate(values);
  };

  return (
    <div className={styles.baseRangeFilter}>
      <div className={styles.wrapper}>
        <div className={styles.fields}>
          <label>
            {minLabel}
            <div className={styles.field}>
              {InputIcon && <InputIcon />}
              <input
                disabled={disabled}
                placeholder={minPlaceholder ?? domain[0]?.toString() ?? ''}
                type="number"
                pattern="\d*"
                onChange={(e) => handleInputChange('min', e.target.value)}
                value={inputMinMax[0]}
                data-testid="input-min"
              />
            </div>
          </label>
          <label>
            {maxLabel}
            <div className={styles.field}>
              {InputIcon && <InputIcon />}
              <input
                disabled={disabled}
                placeholder={maxPlaceholder ?? domain[1]?.toString() ?? ''}
                type="number"
                pattern="\d*"
                onChange={(e) => handleInputChange('max', e.target.value)}
                value={inputMinMax[1]}
                data-testid="input-max"
              />
            </div>
          </label>
        </div>
        <FilterRangeSlider
          disabled={disabled || values[0] === values[1]}
          domain={domain}
          values={values}
          onSlideEnd={handleSliderChange}
          onUpdate={handleSliderUpdate}
          step={step}
        />
      </div>
    </div>
  );
};

export default React.memo(BaseRangeFilter);
