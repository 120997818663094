'use client';

import React from 'react';
import _CountUp from 'react-countup';
import { usePrevious } from 'react-use';
import { inflect } from 'inflection';

import { capitalizeName } from '@/common/lib/utils';

import styles from './styles.module.scss';

interface Props {
  total: number;
  bodyTypes: string[];
  listingTypes: string[];
}

// Bad ESM export
const CountUp = _CountUp as unknown as typeof _CountUp;

export default function ResultsCount({ total, bodyTypes, listingTypes }: Props) {
  const prevTotal = usePrevious(total);

  return (
    <>
      <h1 className={styles.resultsCount}>
        <strong>
          <CountUp
            start={prevTotal !== undefined ? prevTotal : total}
            end={total}
            useEasing={false}
            duration={0.25}
            formattingFn={(val) => val.toLocaleString()}
          />{' '}
          {listingTypes.length === 1 ? capitalizeName(listingTypes[0]) : ''}{' '}
          {bodyTypes.length === 1 ? bodyTypes[0] : ''} {inflect('Car', total, 'car', 'cars')}
        </strong>{' '}
        for sale
      </h1>
    </>
  );
}
