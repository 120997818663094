import React, { useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { inflect } from 'inflection';

import Button from '@/common/components/Button/index.tsx';
import Filters from '@/listing-website/components/SearchDirectory/Filters/index.tsx';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import IconAdjustmentsHorizontal from '@/common/icons/tabler/adjustments-horizontal.svg';
import IconX from '@/common/icons/tabler/x.svg';

import FilterTagBar from '../SearchDirectory/components/FilterTagBar/index.tsx';

import styles from './styles.module.scss';

interface Props {
  filters: SearchDirectory.Filters;
  filterOptions: SearchDirectory.FilterOptions;
  total: number;
}

const MobileFilters = ({ filters, filterOptions, total }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);
  const totalFilters = StockSearch.getFilterActiveCount(filters);

  return (
    <>
      <Button data-testid="mobilefilters-button" onClick={() => setIsOpen(true)} styleOverride={styles.button}>
        <IconAdjustmentsHorizontal height={24} width={24} />
        <p>Filter</p>
        {!!(totalFilters > 0) && <span className={styles.badge}>{totalFilters}</span>}
      </Button>
      <ReactModal
        overlayClassName={{
          base: styles.modal,
          afterOpen: styles.modalIsOpen,
          beforeClose: styles.modalClosing,
        }}
        bodyOpenClassName={styles.overrideBodyClassname}
        className={styles.modalContent}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick
        closeTimeoutMS={200}
        ariaHideApp={false}
      >
        <div className={styles.panel}>
          <div className={styles.heading}>
            <p>Filter results by</p>
            <Button styleOverride={styles.closeButton} size="S" onClick={() => setIsOpen(false)}>
              <IconX width={24} height={24} />
            </Button>
          </div>
          <hr />
          <div className={styles.content} ref={filterRef}>
            <FilterTagBar showResetButton={true} isFilterMenu={true} filters={filters} />
            <div className={styles.filters}>
              <Filters filters={filters} filterOptions={filterOptions} />
            </div>
          </div>
          <div className={styles.showResults}>
            <Button colour="action" style="solid" onClick={() => setIsOpen(false)}>
              Show {total} {inflect('Result', total, 'result', 'results')}
            </Button>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default MobileFilters;
