'use client';

import React, { useMemo } from 'react';
import cc from 'classcat';

import IconChevronLeft from '@/common/icons/tabler/chevron-left.svg';
import IconChevronRight from '@/common/icons/tabler/chevron-right.svg';

import styles from './styles.module.scss';

type Props = {
  totalPages: number; // Total pages to show
  currentPage: number; // Current page we're on
  displayAmount: number; // How many pages to show between '<' and '>'
  totalItems: number; // How many items there are in total
  limit: number;
  disabled?: boolean;
  itemType: string;
  onPageChange: (pageNumber: number) => void;
};
const Pagination = ({
  totalPages,
  currentPage,
  displayAmount,
  totalItems,
  limit,
  disabled = false,
  itemType,
  onPageChange,
}: Props) => {
  const pageArr = useMemo(() => {
    let arr = Array.from({ length: totalPages }, (_, i) => i + 1);

    if (currentPage > displayAmount) {
      const max = displayAmount * Math.ceil(currentPage / displayAmount);
      arr = arr.slice(max - displayAmount, max < totalPages ? max : totalPages);
    } else {
      arr = arr.slice(0, displayAmount);
    }
    return arr;
  }, [currentPage, totalPages, displayAmount]);

  if (currentPage > totalPages) {
    return null;
  }

  return (
    <div className={styles.paginationWrapper}>
      <div className={styles.pagination}>
        {currentPage !== 1 && (
          <button
            data-testid="pagination-button-lt"
            className={cc({ [styles.next]: true, [styles.disabled]: disabled })}
            onClick={() => onPageChange(currentPage - 1)}
          >
            <IconChevronLeft />
          </button>
        )}
        {!pageArr.includes(1) && (
          <button
            data-testid="pagination-button-first-page"
            className={cc({ [styles.active]: currentPage === 1 })}
            onClick={() => onPageChange(1)}
          >
            1
          </button>
        )}
        {!pageArr.includes(2) && totalPages > 1 && <span data-testid="pagination-button-first-range">...</span>}
        {pageArr.map((x) => (
          <button
            className={cc({ [styles.active]: x === currentPage, [styles.disabled]: x === currentPage })}
            data-testid="pagination-button-number"
            onClick={() => onPageChange(x)}
            key={x}
          >
            {x}
          </button>
        ))}
        {!pageArr.includes(totalPages - 1) && !pageArr.includes(totalPages) && (
          <span data-testid="pagination-button-last-range">...</span>
        )}
        {!pageArr.includes(totalPages) && (
          <button
            data-testid="pagination-button-last-page"
            className={cc({ [styles.active]: currentPage === totalPages })}
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </button>
        )}
        {currentPage !== totalPages && (
          <button
            data-testid="pagination-button-gt"
            className={cc({ [styles.next]: true, [styles.disabled]: disabled })}
            onClick={() => onPageChange(currentPage + 1)}
          >
            <IconChevronRight />
          </button>
        )}
      </div>
      <p>
        Displaying {((currentPage - 1) * limit + 1).toLocaleString()}-
        {Math.min(currentPage * limit, totalItems).toLocaleString()} of {totalItems.toLocaleString()} {itemType}
      </p>
    </div>
  );
};

export default Pagination;

export function applyPagination<T>(items: Array<T>, currentPage: number, limit: number) {
  const totalItems = items.length;
  const totalPages = Math.ceil(totalItems / limit);

  const pagedItems = items.slice((currentPage - 1) * limit, currentPage * limit);
  return { pagedItems, totalItems, totalPages };
}
