import React, { useMemo, useState } from 'react';

import Breadcrumbs, { Crumb } from '@/common/components/Breadcrumbs/index.tsx';
import FilterButton from '@/common/components/FilterButton/index.tsx';
import FilterDropdown from '@/common/components/FilterDropdown/index.tsx';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import IconMap2 from '@/common/icons/tabler/map-2.svg';

import styles from './styles.module.scss';

export type LocationType = 'state' | 'region';

export type LocationFilterButton = {
  text: string;
  type: LocationType;
};

interface LocationFilterProps {
  heading?: string;
  shouldHideRegion?: boolean;
  states: Array<SearchFilterOptionState>;
  dealershipLocationQueries: SearchDirectory.DealershipLocationQueries;
  onChange: (dealershipLocationQueries: SearchDirectory.DealershipLocationQueries) => void;
}

const LocationFilter = ({
  heading = 'State/Region',
  states,
  dealershipLocationQueries = [],
  onChange,
}: LocationFilterProps) => {
  const dealershipLocations = dealershipLocationQueries || [];
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [loadingOption, setLoadingOption] = useState<string>('');

  const handleSelect = (option: SearchDirectory.LocationsFilterOption) => {
    setLoadingOption(option.handle);

    switch (option.__typename) {
      case 'SearchFilterOptionState':
        onChange(StockSearch.nextDealershipLocationQuery({ state: option.handle }, dealershipLocations));
        break;
      case 'SearchFilterOptionCity':
        onChange(
          StockSearch.nextDealershipLocationQuery({ state: selectedState, city: option.handle }, dealershipLocations),
        );

        break;
      case 'SearchFilterOptionSuburb':
        onChange(
          StockSearch.nextDealershipLocationQuery(
            { state: selectedState, city: selectedCity, suburb: option.handle },
            dealershipLocations,
          ),
        );
        break;
      default:
        break;
    }

    setLoadingOption('');
  };

  const filterOptions = useMemo(() => {
    if (selectedState && !selectedCity) {
      return states.find((state) => state.handle === selectedState)?.cities || [];
    } else if (selectedState && selectedCity) {
      return (
        states.find((state) => state.handle === selectedState)?.cities?.find((city) => city.handle === selectedCity)
          ?.suburbs || []
      );
    } else {
      return states;
    }
  }, [selectedCity, selectedState, states]);

  const breadcrumbs = useMemo(() => {
    const crumbs: Crumb[] = [];
    if (selectedCity) {
      crumbs.unshift({ text: 'Suburb' });
    }
    if (selectedState) {
      crumbs.unshift({ text: 'Region', onClick: () => setSelectedCity('') });
      crumbs.unshift({ text: 'State', onClick: () => setSelectedState('') });
    }
    return crumbs;
  }, [selectedCity, selectedState]);

  return (
    <div className={styles.locationFilter}>
      <FilterDropdown
        heading={heading}
        Icon={() => <IconMap2 height={24} width={24} />}
        defaultOpen={false}
        countActive={0}
      >
        {breadcrumbs.length > 0 && (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs showHome={false} crumbs={breadcrumbs} />
          </div>
        )}
        <div className={styles.filters}>
          {filterOptions.map((option) => {
            switch (option.__typename) {
              case 'SearchFilterOptionState':
                return (
                  <FilterButton
                    key={option.handle}
                    label={option.name}
                    loading={loadingOption === option.handle}
                    onClick={() => handleSelect(option)}
                    checked={dealershipLocations?.some((loc) => loc.state === option.handle) || false}
                    checkedButtonText="Select region"
                    checkedButtonOnClick={() => setSelectedState(option.handle)}
                  />
                );
              case 'SearchFilterOptionCity':
                return (
                  <FilterButton
                    key={option.handle}
                    label={option.name}
                    loading={loadingOption === option.handle}
                    onClick={() => handleSelect(option)}
                    checked={dealershipLocations?.some((loc) => loc.city === option.handle) || false}
                    checkedButtonText="Select suburb"
                    checkedButtonOnClick={() => setSelectedCity(option.handle)}
                  />
                );
              case 'SearchFilterOptionSuburb':
                return (
                  <FilterButton
                    key={option.handle}
                    label={option.name}
                    loading={loadingOption === option.handle}
                    onClick={() => handleSelect(option)}
                    checked={dealershipLocations?.some((loc) => loc.suburb === option.handle) || false}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
      </FilterDropdown>
    </div>
  );
};

export default LocationFilter;
