import React from 'react';
import cc from 'classcat';
import { xor } from 'lodash';

import FilterButton from '@/common/components/FilterButton/index.tsx';
import FilterDropdown, { FilterDropdownProps } from '@/common/components/FilterDropdown/index.tsx';

import IconPalette from '@/common/icons/tabler/palette.svg';

import styles from './styles.module.scss';

type Props = Partial<Omit<FilterDropdownProps, 'children'>> & {
  selectedColours: Array<string>;
  colourOptions: Array<SearchFilterOptionText>;
  onClick: (colours: Array<string>) => void;
};

const ColourFilter = ({
  heading = 'Colour',
  Icon = () => <IconPalette />,
  selectedColours = [],
  colourOptions,
  onClick,
  ...optionalFilterDropdownProps
}: Props) => {
  return (
    <FilterDropdown
      heading={heading}
      Icon={Icon}
      {...optionalFilterDropdownProps}
      defaultOpen={false}
      countActive={selectedColours?.length}
    >
      <div className={styles.colourFilter} data-testid="colour-filter">
        {colourOptions.map((colourOption) => (
          <FilterButton
            key={colourOption.handle}
            label={colourOption.name}
            checked={selectedColours?.includes(colourOption.name) || false}
            onClick={() => onClick(xor(selectedColours, [colourOption.name]))}
            Icon={() => <div className={cc([styles.colourCircle, styles[colourOption.name.toLowerCase()]])} />}
          />
        ))}
      </div>
    </FilterDropdown>
  );
};

export default ColourFilter;
