import React, { useCallback, useEffect } from 'react';
import cc from 'classcat';
import { useToggle } from 'react-use';

import styles from './styles.module.scss';

interface Props {
  onClick: (toggled: boolean) => void;
  toggled?: boolean;
  initialToggled?: boolean;
  size?: 'small' | 'medium';
}

// If `toggled` is provided, the component will be completely controlled
// Otherwise it will handle the toggle state itself.
const Toggle = ({ onClick, toggled, initialToggled, size = 'medium' }: Props) => {
  const [isToggled, toggle] = useToggle(initialToggled || toggled || false);

  const handleClick = useCallback(() => {
    onClick(!isToggled);

    if (toggled === undefined) {
      toggle();
    }
  }, [isToggled, onClick, toggle, toggled]);

  useEffect(() => {
    if (toggled !== undefined) {
      toggle(toggled);
    }
  }, [toggle, toggled]);

  let classNames = cc({
    [styles.toggle]: true,
  });

  if (size && styles[size]) {
    classNames = cc([classNames, styles[size]]);
  }

  return (
    <div className={classNames}>
      <button
        type="button"
        className={cc({ [styles.button]: true, [styles.active]: isToggled })}
        onClick={handleClick}
        data-testid="toggle"
      >
        <div className={cc([styles.status, styles.activeText])}>On</div>
        <div className={styles.status}>Off</div>
        <div className={styles.circle} />
      </button>
    </div>
  );
};

export default Toggle;
