import React from 'react';

import FilterDropdown, { FilterDropdownProps } from '@/common/components/FilterDropdown/index.tsx';
import BaseRangeFilter, {
  BaseRangeFilterProps,
} from '@/listing-website/components/SearchDirectory/Filters/components/BaseRangeFilter/index.tsx';

interface Props
  extends Omit<BaseRangeFilterProps, 'values' | 'onUpdate' | 'onChange'>,
    Omit<FilterDropdownProps, 'children'> {
  onFilterChange: (values: [number, number]) => void;
  values?: [number, number];
}

const RangeFilter = ({ heading, Icon, domain, onFilterChange, values, ...baseRangeFilterProps }: Props) => {
  return (
    <FilterDropdown heading={heading} Icon={Icon} defaultOpen={!!values} countActive={!!values ? 1 : 0}>
      <BaseRangeFilter
        values={values ?? (domain as number[])}
        domain={domain}
        disabled={domain[0] === domain[1]}
        onChange={([min, max]) => onFilterChange([min, max])}
        {...baseRangeFilterProps}
      />
    </FilterDropdown>
  );
};

export default RangeFilter;
