'use client';

import React from 'react';
import { useRouter } from 'next/navigation.js';

import Pagination from '@/common/components/Pagination/index.tsx';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import styles from './styles.module.scss';

interface Props {
  filters: SearchDirectory.Filters;
  totalPages?: number;
  totalItems?: number;
  currentPage?: number;
}

export default function StockPagination({ totalItems = 1, totalPages = 1, currentPage = 1, filters }: Props) {
  const { push } = useRouter();
  const setCurrentPageAndScroll = (page: number) => {
    push(StockSearch.buildURL({ ...filters, page }));
    if (typeof window !== 'undefined') {
      // didn't include behavior smooth as the height of the page collapses after scrolling to the top and changing scroll height
      window.scrollTo({ top: 0 });
    }
  };

  return (
    <div className={styles.pagination}>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        limit={10}
        itemType="Cars"
        displayAmount={4}
        totalItems={totalItems}
        onPageChange={setCurrentPageAndScroll}
      />
    </div>
  );
}
