import React, { useState } from 'react';

import FilterDropdown from '@/common/components/FilterDropdown/index.tsx';
import BaseRangeFilter from '@/listing-website/components/SearchDirectory/Filters/components/BaseRangeFilter/index.tsx';

import IconCurrencyDollar from '@/common/icons/tabler/currency-dollar.svg';

interface Prop {
  onFilterChange: (values: [number, number]) => void;
  values?: [number, number];
  maxPriceVisibleOnFilter: number;
}

const PriceFilter = ({ onFilterChange, maxPriceVisibleOnFilter, values }: Prop) => {
  const [baseDomain] = useState<number[]>([0, maxPriceVisibleOnFilter]);
  const [priceDomain] = useState<number[]>([
    Math.floor(baseDomain[0] / PriceFilter.step) * PriceFilter.step,
    Math.min(Math.ceil(baseDomain[1] / PriceFilter.step) * PriceFilter.step, maxPriceVisibleOnFilter),
  ]);

  return (
    <FilterDropdown
      heading="Repayments"
      Icon={() => <IconCurrencyDollar />}
      defaultOpen={!!values}
      countActive={!!values ? 1 : 0}
    >
      <BaseRangeFilter
        InputIcon={() => <IconCurrencyDollar />}
        minLabel="Min"
        maxLabel="Max"
        minPlaceholder="0"
        maxPlaceholder="Any"
        domain={priceDomain}
        values={values ?? priceDomain}
        step={PriceFilter.step}
        onChange={([min, max]) => onFilterChange?.([min, max])}
        showMaxPlaceholderAtMaxValue
      />
    </FilterDropdown>
  );
};

PriceFilter.step = 5;

export default PriceFilter;
