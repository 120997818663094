import React from 'react';

import FilterButton from '@/common/components/FilterButton/index.tsx';
import FilterDropdown, { FilterDropdownProps } from '@/common/components/FilterDropdown/index.tsx';

import styles from './styles.module.scss';

interface Props extends Omit<FilterDropdownProps, 'children'> {
  filterOptions?: Array<string>;
  selectedOptions?: Array<string>;
  onChange: (selectedOptions: Array<string>) => void;
}

const ListFilter = ({ filterOptions = [], onChange, selectedOptions = [], ...filterDropdownProps }: Props) => {
  const handleFilterClick = (option: string) => {
    const newSelectedFilters = selectedOptions.includes(option)
      ? selectedOptions.filter((opt) => opt !== option)
      : [...selectedOptions, option];

    onChange(newSelectedFilters);
  };

  return (
    <FilterDropdown {...filterDropdownProps} defaultOpen={false} countActive={selectedOptions.length}>
      <div className={styles.listFilter}>
        {filterOptions.map((opt) => (
          <FilterButton
            key={opt}
            label={opt}
            checked={selectedOptions.includes(opt)}
            onClick={() => handleFilterClick(opt)}
          />
        ))}
      </div>
    </FilterDropdown>
  );
};

export default ListFilter;
