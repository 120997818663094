'use client';

import React, { useCallback, useMemo } from 'react';
import cc from 'classcat';
import { useRouter } from 'next/navigation';

import Breadcrumbs from '@/common/components/Breadcrumbs/index.tsx';
import { baseURL } from '@/listing-website/lib/content.ts';
import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import Logo from '@/common/icons/fleetcare-logo-dark.svg';
import IconMinusVertical from '@/common/icons/tabler/minus-vertical.svg';

import MobileFilters from '../MobileFilters/index.tsx';
import VehicleCard from '../VehicleCard/index.tsx';
import FilterTagBar from './components/FilterTagBar/index.tsx';
import PromotionTileCard from './components/PromotionTileCard/index.tsx';
import ResultsCount from './components/ResultsCount/index.tsx';
import Search from './components/Search/index.tsx';
import SetLocation from './components/SetLocation/index.tsx';
import SetSortBy from './components/SetSortBy/index.tsx';
import Filters from './Filters/index.tsx';
import StockPagination from './StockPagination/index.tsx';

import styles from './styles.module.scss';

interface Props {
  filters: SearchDirectory.Filters;
  filterOptions: SearchDirectory.FilterOptions;
  stockItems: Array<Stock>;
  totalPages?: number;
  totalItems?: number;
}

export default function SearchDirectory({ filters, filterOptions, stockItems, totalPages = 1, totalItems = 0 }: Props) {
  const page = filters.page;

  const router = useRouter();

  const handleClickLink = useCallback(
    (make?: string) => {
      router.push(
        StockSearch.buildURL(
          make
            ? {
                ...filters,
                vehicleSearchQueries: make ? StockSearch.nextVehicleSearchQuery({ make: make }, []) : [],
                page: undefined,
              }
            : {},
        ),
      );
    },
    [filters, router],
  );

  const breadcrumbs = useMemo(() => {
    const queries = filters.vehicleSearchQueries;

    if (!queries?.length) {
      return [{ text: 'All Cars' }];
    }

    if (queries.length === 1) {
      return [
        { text: 'All Cars', onClick: () => handleClickLink() },
        ...Object.values(queries[0]).map((value) => ({ text: value || '' })),
      ];
    }

    if (queries.length > 1) {
      const makes = new Set(queries.map((q) => q.make).filter(Boolean));
      const models = new Set(queries.map((q) => q.model).filter(Boolean));

      if (makes.size === 1 && models.size > 1) {
        return [{ text: 'All Cars', onClick: () => handleClickLink() }, { text: Array.from(makes)[0] || '' }];
      } else if (makes.size === 1 && models.size === 1) {
        return [
          { text: 'All Cars', onClick: () => handleClickLink() },
          { text: Array.from(makes)[0] || '', onClick: () => handleClickLink(Array.from(makes)[0]) },
          { text: Array.from(models)[0] || '' },
        ];
      }
    }

    return [{ text: 'All Cars' }];
  }, [filters, handleClickLink]);

  return (
    <div className={styles.searchDirectory}>
      <div className={styles.left}>
        <Filters filters={filters} filterOptions={filterOptions} />
      </div>
      <div className={styles.right}>
        <div className={styles.searchTop}>
          <Breadcrumbs showHome={false} crumbs={breadcrumbs} icon={<IconMinusVertical />} />
        </div>
        <div
          className={cc({
            [styles.headerGrid]: true,
            [styles.scrollPassed]: true,
          })}
        >
          <div className={styles.searchCell}>
            <Search filters={filters} />
          </div>
          <div className={styles.filtersCell} data-hide-only="desktop">
            <MobileFilters filters={filters} filterOptions={filterOptions} total={totalItems} />
          </div>
          <div className={styles.locationCell}>
            <SetLocation filters={filters} />
          </div>

          <div className={styles.filterCountCell} data-show-only="desktop">
            <div className={styles.filterTagBar}>
              <FilterTagBar showResetButton={true} animateHeight={false} filters={filters} />
            </div>
          </div>

          <div className={styles.countCell} data-show-only="desktop">
            <ResultsCount
              total={totalItems}
              bodyTypes={filters.bodyTypes || []}
              listingTypes={filters.listingTypes || []}
            />
          </div>
          <div className={styles.sortCell} data-testid="sortCell">
            <SetSortBy filters={filters} />
          </div>
        </div>
        <div data-hide-only="desktop">
          <ResultsCount
            total={totalItems}
            bodyTypes={filters.bodyTypes || []}
            listingTypes={filters.listingTypes || []}
          />
        </div>
        <div className={styles.results}>
          {/* preload first 10 */}
          {stockItems.map((stock, index) => (
            <VehicleCard
              vehicle={stock}
              preload={index <= 5}
              key={stock.guid}
              showCTAButton
              showDealerComments={false}
            />
          ))}
          <PromotionTileCard
            logo={<Logo height={32} width={115} />}
            heading="Novated Leasing"
            body="A finance option that allows you to access some of your salary before-tax. It’s a cost-effective way to get into your next car."
            buttonText="Learn about Novated Leasing"
            link={`${baseURL}/novated-leasing`}
          />
        </div>
        <div className={styles.pagination}>
          <StockPagination totalItems={totalItems} totalPages={totalPages} currentPage={page || 1} filters={filters} />
        </div>
      </div>
    </div>
  );
}
