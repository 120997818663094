'use client';

import React, { useCallback, useOptimistic, useTransition } from 'react';
import { maxBy, minBy } from 'lodash';
import { useRouter } from 'next/navigation.js';

import StockSearch from '@/listing-website/lib/StockSearch/index.ts';

import IconCalendar from '@/common/icons/tabler/calendar.svg';
import IconCarCrash from '@/common/icons/tabler/car-crash.svg';
import IconCar from '@/common/icons/tabler/car.svg';
import IconChargingPile from '@/common/icons/tabler/charging-pile.svg';
import IconEngine from '@/common/icons/tabler/engine.svg';
import IconManualGearbox from '@/common/icons/tabler/manual-gearbox.svg';
import IconRecharging from '@/common/icons/tabler/recharging.svg';
import IconTags from '@/common/icons/tabler/tags.svg';
import IconUsers from '@/common/icons/tabler/users.svg';

import ColourFilter from './ColourFilter/index.tsx';
import FilterToggle from './components/FilterToggle/index.tsx';
import ListFilter from './components/ListFilter/index.tsx';
import LocationFilter from './components/LocationFilter/index.tsx';
import RangeFilter from './components/RangeFilter/index.tsx';
import MakeModelFilter from './MakeModelFilter/index.tsx';
import PriceFilter from './PriceFilter/index.tsx';

import styles from './styles.module.scss';

interface Props {
  filters: SearchDirectory.Filters;
  filterOptions: SearchDirectory.FilterOptions;
}

export default function Filters({ filters, filterOptions }: Props) {
  const [, startTransition] = useTransition();
  const [optimisticFilters, addOptimisticFilter] = useOptimistic<
    SearchDirectory.Filters,
    Partial<SearchDirectory.Filters>
  >(filters, (state, newFilters) => ({ ...state, ...newFilters }));

  const router = useRouter();

  const minYearOption = minBy(filterOptions.years || [], 'value');
  const maxYearOption = maxBy(filterOptions.years || [], 'value');

  const minSeatsOption = minBy(filterOptions.seats || [], 'value');
  const maxSeatsOption = maxBy(filterOptions.seats || [], 'value');

  const featureOptions = ['Sunroof', 'Airbags', 'Leather Seats', 'Electric seats', 'Android Auto', 'Apple Carplay'];

  const getNextUrl = useCallback(
    (newFilters: Partial<SearchDirectory.Filters>) => {
      return StockSearch.buildURL({ ...filters, page: undefined, ...newFilters });
    },
    [filters],
  );

  const handleFilterChange = (newFilters: Partial<SearchDirectory.Filters>) => {
    startTransition(() => {
      addOptimisticFilter(newFilters);
      router.push(getNextUrl(newFilters));
    });
  };

  const rangeToArray = (range: RangeFloat | RangeInt | undefined | null): [number, number] | undefined => {
    if (!range) return undefined;
    const { min, max } = range;
    return typeof min === 'number' && typeof max === 'number' ? [min, max] : undefined;
  };

  return (
    <div className={styles.filters}>
      <div className={styles.filtersHeading}>
        <p>Filter results by</p>
      </div>
      <hr />
      {!!filterOptions.makes && (
        <MakeModelFilter
          makes={filterOptions.makes}
          vehicleSearchQueries={optimisticFilters.vehicleSearchQueries || []}
          onChange={(vehicleSearchQueries) => handleFilterChange({ vehicleSearchQueries })}
        />
      )}
      {!!filterOptions.fuelTypes?.some((fuelType) => fuelType.name === 'Electric') && (
        <FilterBlock>
          <FilterToggle
            label="Show EVs only"
            Icon={() => <IconRecharging width={24} height={24} />}
            toggled={optimisticFilters.fuelTypes?.includes('Electric') || false}
            onToggle={(toggled) => handleFilterChange({ fuelTypes: toggled ? ['Electric'] : [] })}
          />
        </FilterBlock>
      )}
      {!!filterOptions.listingTypes?.length && (
        <FilterBlock>
          <ListFilter
            heading="Condition"
            Icon={IconCarCrash}
            filterOptions={filterOptions.listingTypes?.map((type) => type.name)}
            selectedOptions={optimisticFilters.listingTypes || []}
            onChange={(selectedOptions) =>
              handleFilterChange({
                listingTypes: selectedOptions.filter((option): option is ListingType =>
                  ['NEW', 'USED', 'DEMO'].includes(option),
                ),
              })
            }
          />
        </FilterBlock>
      )}
      {!!filterOptions.maxWeeklyPrice && (
        <FilterBlock>
          <PriceFilter
            maxPriceVisibleOnFilter={filterOptions.maxWeeklyPrice}
            values={rangeToArray(optimisticFilters.weeklyPriceRange)}
            onFilterChange={([min, max]) => handleFilterChange({ weeklyPriceRange: { min, max } })}
          />
        </FilterBlock>
      )}
      {minYearOption && maxYearOption && (
        <FilterBlock>
          <RangeFilter
            heading="Year"
            Icon={IconCalendar}
            domain={[minYearOption.value, maxYearOption.value]}
            values={rangeToArray(optimisticFilters.yearRange)}
            onFilterChange={([min, max]) => handleFilterChange({ yearRange: { min, max } })}
          />
        </FilterBlock>
      )}
      {minSeatsOption && maxSeatsOption && (
        <FilterBlock>
          <RangeFilter
            heading="Number of seats"
            Icon={IconUsers}
            domain={[minSeatsOption.value, maxSeatsOption.value]}
            values={rangeToArray(optimisticFilters.seatsRange)}
            onFilterChange={([min, max]) => handleFilterChange({ seatsRange: { min, max } })}
          />
        </FilterBlock>
      )}
      {!!filterOptions.transmissions?.length && (
        <FilterBlock>
          <ListFilter
            heading="Transmission"
            Icon={IconManualGearbox}
            filterOptions={filterOptions.transmissions?.map((type) => type.name)}
            selectedOptions={optimisticFilters.transmissions || []}
            onChange={(selectedOptions) => handleFilterChange({ transmissions: selectedOptions })}
          />
        </FilterBlock>
      )}
      {!!filterOptions.colours?.length && (
        <FilterBlock>
          <ColourFilter
            selectedColours={optimisticFilters.colours || []}
            colourOptions={filterOptions.colours}
            onClick={(selectedOptions) => handleFilterChange({ colours: selectedOptions })}
          />
        </FilterBlock>
      )}
      {!!filterOptions.fuelTypes?.length && (
        <FilterBlock>
          <ListFilter
            heading="Fuel Type"
            Icon={IconChargingPile}
            filterOptions={filterOptions.fuelTypes.map((fuelType) => fuelType.name)}
            selectedOptions={optimisticFilters.fuelTypes || []}
            onChange={(selectedOptions) => handleFilterChange({ fuelTypes: selectedOptions })}
          />
        </FilterBlock>
      )}
      {!!filterOptions.driveTypes?.length && (
        <FilterBlock>
          <ListFilter
            heading="Drive Type"
            Icon={IconEngine}
            filterOptions={filterOptions.driveTypes?.map((driveType) => driveType.name)}
            selectedOptions={optimisticFilters.driveTypes || []}
            onChange={(selectedOptions) => handleFilterChange({ driveTypes: selectedOptions })}
          />
        </FilterBlock>
      )}
      {!!featureOptions.length && (
        <FilterBlock>
          <ListFilter
            heading="Features"
            Icon={() => <IconTags />}
            filterOptions={featureOptions.map((feature) => feature)}
            selectedOptions={optimisticFilters.features || []}
            onChange={(selectedOptions) => handleFilterChange({ features: selectedOptions })}
          />
        </FilterBlock>
      )}
      {!!filterOptions.bodyTypes?.length && (
        <FilterBlock>
          <ListFilter
            heading="Body Style"
            Icon={IconCar}
            filterOptions={filterOptions.bodyTypes?.map((bodyType) => bodyType.name)}
            selectedOptions={optimisticFilters.bodyTypes || []}
            onChange={(selectedOptions) => handleFilterChange({ bodyTypes: selectedOptions })}
          />
        </FilterBlock>
      )}
      {!!filterOptions.states?.length && (
        <FilterBlock>
          <LocationFilter
            heading="State/Region"
            states={filterOptions.states}
            dealershipLocationQueries={optimisticFilters.dealershipLocationQueries || []}
            onChange={(dealershipLocationQueries) => handleFilterChange({ dealershipLocationQueries })}
          />
        </FilterBlock>
      )}
    </div>
  );
}

function FilterBlock({ children }: { children: React.ReactNode }) {
  return (
    <>
      <hr />
      {children}
    </>
  );
}
