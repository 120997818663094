import React from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';

import CheckboxChecked from '@/common/icons/checkbox-checked-S.svg';
import CheckboxDefault from '@/common/icons/checkbox-default.svg';
import LeftIcon from '@/common/icons/chevron--forward.svg';
import RadioChecked from '@/common/icons/radiobutton-checked.svg';
import RadioDefault from '@/common/icons/radiobutton-default.svg';

import styles from './styles.module.scss';

interface Props {
  label: string;
  checked: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  radioButton?: boolean;
  checkedButtonText?: string;
  Icon?: () => JSX.Element;
  checkedButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
}

const FilterButton = ({
  label,
  checked,
  onClick,
  disabled = false,
  loading = false,
  radioButton = false,
  checkedButtonText,
  Icon,
  checkedButtonOnClick,
  onKeyDown,
}: Props) => {
  const CheckedIcon = radioButton ? RadioChecked : CheckboxChecked;
  const DefaultIcon = radioButton ? RadioDefault : CheckboxDefault;

  const rightIcon = loading ? (
    <div className={styles.spinner}>...</div>
  ) : checked ? (
    <CheckedIcon width={18} height={18} />
  ) : (
    <DefaultIcon width={18} height={18} />
  );

  return (
    <div className={styles.filterButton}>
      <button
        onClick={onClick}
        onKeyDown={onKeyDown}
        className={cc([styles.button, checked && styles.checked, loading && styles.loading])}
        disabled={disabled}
        data-testid="filter-button"
      >
        {!!Icon && <Icon />}
        <p className={styles.label}>{label}</p>
        <div className={styles.icon}>{rightIcon}</div>
      </button>
      <AnimateHeight
        height={checked && checkedButtonOnClick && checkedButtonText ? 'auto' : 0}
        duration={200}
        className={styles.checkedButtonWrapper}
      >
        <button
          disabled={loading}
          className={styles.checkedButton}
          onClick={checkedButtonOnClick}
          data-testid="checked-icon"
        >
          {checkedButtonText}
          <LeftIcon width={16} height={16} />
        </button>
      </AnimateHeight>
    </div>
  );
};

export default FilterButton;
